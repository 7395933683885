<template>
  <CForm>
    <div class="grid-container">
      <CButton v-for="rt in availableTypes" v-bind:key="rt.id" @click="link(rt.id)" color="info" variant="outline">
        <CIcon name="cil-plus" size="sm" class="align-bottom" /> {{ rt.type }}
        <span class="small">({{ rt.restaurant_linked_types_count }})</span>
      </CButton>
    </div>

    <strong v-if="!availableTypes.length">No available restaurant types found!</strong>
  </CForm>
</template>

<script>

export default {
  name: 'AvailableTypes',
  props: {
    availableTypes: { default: [], type: Array },
  },
  data() {
    return {};
  },

  methods: {
    link(typeId) {
      const type = this.availableTypes.find((o) => o.id === typeId);
      this.$emit("linked", type);

      const index = this.availableTypes.findIndex((o) => o.id === typeId);
      this.availableTypes.splice(index, 1);
    },
  }
}
</script>

<style scoped>
.grid-container {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(140px, 200px));
  padding: 0px 10px;
}

@media (max-width: 575.98px) {
  .grid-container {
    grid-template-columns: repeat(auto-fit, minmax(120px, 120px));
    padding: 0;
  }
  .grid-container .btn {
    padding: 0.175rem;
  }
  .grid-container .btn > svg{
    display: none;
  }
}
</style>
