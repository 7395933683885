<template>
  <CRow>
    <CCol sm="12">
      <CCard v-if="restaurantName">
        <CCardHeader>
          <CIcon name="cil-text" />
          <h5 class="d-inline ml-2">
            <h3 class="d-inline"><CBadge color="secondary">{{ this.restaurantName | uppercase }}</CBadge></h3>
            Linked Types
          </h5>

          <div class="card-header-actions">
            <CButton v-if="restaurantId" color="primary" :to="{name: 'View Restaurant', params: {'id': restaurantId}}" class="ml-2">
              <CIcon name="cil-arrow-thick-left" class="align-bottom" /> <strong>Back</strong> To Restaurant
            </CButton>
          </div>
        </CCardHeader>

        <CCardBody>
          <LinkedTypes :restaurantId="restaurantId" :linkedTypes.sync="linkedTypes" />

          <!-- Actions -->
          <CCard class="actions sticky-bottom">
            <CCardBody class="p-2">
              <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
                <div>
                  <CButton
                    color="primary"
                    :disabled="loading || submitted"
                    @click="storeLinkedTypes"
                  >
                    <CSpinner v-if="submitted" size="sm" />
                    <CIcon v-else name="cil-send"/>
                    <strong> Submit</strong>
                  </CButton>
                </div>

                <div class="ml-auto">
                  <h5 class="mt-1">Total: <span class="d-inline count bg-primary pb-1">{{ totalLinkedTypes }}</span></h5>
                </div>
              </div>
            </CCardBody>
          </CCard>

        </CCardBody>
      </CCard>

      <div v-else>
        <CSpinner v-if="loading" color="primary" />
        <h6 v-else>Restaurant not found!</h6>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import LinkedTypes from "./LinkedTypes";

export default {
  name: "RestaurantLinkedTypes",
  components: {
    LinkedTypes
  },
  data() {
    return {
      restaurantName: null,
      linkedTypes: [],
      loading: true,
      submitted: false,
    };
  },

  computed: {
    restaurantId() { return this.$route.params.restaurant_id },
    totalLinkedTypes() { return this.linkedTypes.length; },
  },

  async mounted() {
    await this.getRestaurantName();
    await this.getLinkedTypes();
  },

  methods: {
    async getRestaurantName() {
      await this.$axios
        .get(this.$backend.RESTAURANT.GET_NAME.replace("{id}", this.restaurantId))
        .then((response) => {
          this.restaurantName = response.data.restaurant_name;
        })
        .finally(() => (this.loading = false));
    },

    async getLinkedTypes() {
      this.loading = true;
      var url = new URL(this.$backend.RESTAURANT_LINKED_TYPES.GET_ALL.replace("{restaurant_id}", this.restaurantId));

      await this.$axios.get(url)
        .then((response) => {
          this.linkedTypes = response.data.map((t) => {
            return {
              id: t.id,
              type: t.type,
              type_image_path: t.type_image_path,
              index: t.pivot.index,
            };
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async storeLinkedTypes() {
      const linkedTypesWithIndex = this.linkedTypes.map((t) => {
        return { id: t.id, index: t.index };
      });

      this.submitted = true;

      this.$axios.post(this.$backend.RESTAURANT_LINKED_TYPES.STORE.replace("{restaurant_id}", this.restaurantId), linkedTypesWithIndex)
        .then(() => {
          this.$toast.success(`Restaurant types successfully linked.`);
        })
        .catch((error) => {
          this.$toast.error(`${error.response.data.message}`);
        })
        .finally(() => {
          this.submitted = false;
        });
    }
  },
};
</script>
