<template>
  <div class="row">
    <div class="col-12">
      <h5 class="mb-3">Available Types</h5>
      <AvailableTypes v-if="!loading" :availableTypes.lazy="availableTypes" @linked="link" />
      <CSpinner v-else color="primary" size="sm" class="mb-2" />
    </div>

    <div class="col-12">
      <h5 class="mt-5 mb-3">Linked Types</h5>
      <CDataTable
        v-if="!loading || linkedTypes.length"
        class="linked-types"
        hover
        striped
        :items="linkedTypes"
        :fields="fields"
        :noItemsView="{
          noResults: 'No filtering results are available!',
          noItems: 'No linked types found!',
        }"
      >
        <!-- Move Index -->
        <template #move_index="{ item }">
          <td class="move-item align-middle">
            <CIcon name="cil-move" :data-id="item.id" class="align-bottom" />
          </td>
        </template>

        <!-- Restaurant Type Image -->
        <template #type_image_path="{item}">
          <td>
            <CImg
              :src="item.type_image_path"
              class="c-avatar-img"
              style="width: 36px; height: 36px"
              placeholderColor="lightgray"
            />
          </td>
        </template>

        <!-- Restaurant Type Name -->
        <template #type="{item}">
          <td class="align-middle">
            {{ item.type }}
          </td>
        </template>

        <!-- Actions -->
        <template #actions="{ item, index }">
          <td class="align-middle text-center">
            <CButton
              size="sm"
              color="danger"
              shape="pill"
              variant="ghost"
              @click="unlink(item, index)"
            >
              <CIcon name="cil-trash" />
            </CButton>
          </td>
        </template>
      </CDataTable>
      <CSpinner v-else color="primary" size="sm" class="mb-3" />
    </div>
  </div>
</template>

<script>
import Sortable from "sortablejs";
import AvailableTypes from "./AvailableTypes";

export default {
  name: "LinkedTypes",
  components: {
    AvailableTypes
  },
  props: {
    restaurantId: { default: null },
    linkedTypes: { default: [], type: Array },
  },
  data() {
    return {
      availableTypes: [],
      fields: [
        { key: "move_index",      label: "",            filter: false, sorter: false, _style: "width: 5%;" },
        { key: "type_image_path", label: "",            filter: false, sorter: false, _style: "width: 5%" },
        { key: "type",            label: "Name",        filter: false, sorter: false, _style: "width: 75%" },
        { key: "actions",         label: "Actions",     filter: false, sorter: false, _style: "min-width: 1%; width: 5%" }
      ],

      loading: false,
    };
  },

  computed: {
    lastItemIndex() {
      if (this.linkedTypes.length == 0) {
        return 0;
      }

      const maxIndex = Math.max(...this.linkedTypes.map((t) => t.index));
      return maxIndex ?? 0;
    },
  },

  async mounted() {
    await this.getAvailableTypes();

    //#region Sortablejs işlemleri
    var itemID, newIndex;
    var el = document.querySelector(".linked-types tbody");

    var sortable = Sortable.create(el, {
      handle: "svg.c-icon",
      chosenClass: "table-selected",
      animation: 150,
      easing: "cubic-bezier(1, 0, 0, 1)",

      onSort: function (/**Event*/ evt) {
        var itemEl = evt.item; // dragged HTMLElement
        newIndex = evt.newIndex;
        itemID = itemEl.getElementsByTagName("svg")[0].getAttribute("data-id");
        // console.log(itemID, newIndex);
      },
      store: {
        /**
         * Save the order of elements. Called onEnd (when the item is dropped).
         * @param {Sortable}  sortable
         */
        set: () =>
          this.storeNewIndexes(parseInt(itemID), newIndex),
      },
    });
    //#endregion Sortablejs ---
  },

  methods: {
    async getAvailableTypes() {
      this.loading = true;
      await this.$axios
        .get(this.$backend.RESTAURANT_LINKED_TYPES.UNLINKED_RESTAURANT_TYPES.replace("{restaurant_id}", this.restaurantId))
        .then((response) => {
          this.availableTypes = response.data.filter(t1 => !this.linkedTypes.some(t2 => t1.id === t2.id));
        })
        .finally(() => {
          this.loading = false;
        });
    },

    storeNewIndexes(itemID, newIndex) {
      // console.log(itemID, newIndex);

      if(isNaN(itemID) || isNaN(newIndex))
        return;

      // Dizi index'i 0'dan başlıyor, ancak bizim 0. elemanın index'i 1'den başlıyor.
      newIndex++;

      // For Front-End
      var item = this.linkedTypes.find(item => item.id == itemID);
      var oldIndex = item.index;
      if (newIndex > oldIndex) {
        this.linkedTypes.filter((item) => {
          return item.index > oldIndex && item.index <= newIndex;
        }).forEach((value, index) => { value.index--; });
      }
      else if (newIndex < oldIndex) {
        this.linkedTypes.filter((item) => {
          return item.index < oldIndex && item.index >= newIndex;
        }).forEach((value, index) => { value.index++; });
      }
      item.index = newIndex;
    },

    link(type) {
      let {type_image, ...t} = type;

      const newType = {
        ...t,
        index: this.lastItemIndex + 1,
      };

      this.linkedTypes.push(newType);
      // this.linkedTypes = this.linkedTypes.concat([newType]);
    },

    unlink(type, idx) {
      this.$confirm({
        title: 'Confirm',
        message: `⚠️ Do you really want to unlink the type?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            this.$delete(this.linkedTypes, idx);
            this.linkedTypes = this.linkedTypes.sort((a,b) => a.index - b.index);

            let {index, ...t} = type;
            this.availableTypes.push(t);
          }
        },
      });
    },
  },
};
</script>
